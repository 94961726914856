import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import imgHandsAroundShield from "../../images/health-tracker/safe-hands.svg"
import imgDoctor from "../../images/health-tracker/smiling-female-doctor-with-glasses.png";
import imgInfo from "../../images/info_icon.png";
import {composeUrl} from "../../utils/api";
import HealthTrackerBanner from "./HealthTrackerBanner";
import {healthTrackerDocs} from "../Documents/docs";
import {useViewport} from "react-viewport-hooks";

function ProductInfo() {
    const {vw} = useViewport();
    const auth = useAuth();
    const navigate = useNavigate();

    let layout;
    if (vw <= 1024 && vw >= 768) {
        layout = 'tablet';
    } else if (vw < 768) {
        layout = 'mobile';
    } else {
        layout = 'desktop';
    }

    // check if states can be used to switch between different results pages
    const navigateToPreviousResults = () => navigate("/HealthTracker/results", {state: {showHealthTrackerHome: false}});
    const navigateToDocs = () => navigate("/documents");

    const [loading, setLoading] = useState<boolean>(true);
    const [nextAllowedScan, setNextAllowedScan] = useState<string>();
    const [isScanAllowed, setIsScanAllowed] = useState<boolean>(false);
    const [acceptedDataNotShared, setAcceptedDataNotShared] = useState<boolean>(false);

    const navigateToHome = () => {
        if (isScanAllowed) {
            navigate("/HealthTracker", {state: {showHealthTrackerHome: true}});
        }
    };

    function acceptDataSharingNotice() {
        setAcceptedDataNotShared(true);
    }

    const fetchNextScanDate = () => {
        setLoading(true);
        fetch(composeUrl('v1/healthTracker/eligibility'), {
            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
            method: 'GET'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const nextScanDate = new Date(data.nextHealthAssessmentAvailableFrom);
                startAutoUpdate(nextScanDate);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    React.useEffect(() => {
        fetchNextScanDate();
    }, []);

    function calculateTimeDifference(targetDate: Date): { days: number, hours: number, minutes: number } {
        const now = new Date();
        const diff = new Date(targetDate).getTime() - now.getTime();

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

        return {days, hours, minutes};
    }

    function startAutoUpdate(targetDate: Date) {
        if (targetDate !== undefined && targetDate !== null) {
            const updateDisplay = () => {
                const {days, hours, minutes} = calculateTimeDifference(targetDate);

                if (days < 0 || hours < 0 || minutes < 0) {
                    setIsScanAllowed(true);
                    setNextAllowedScan("Your scan is available now.");
                } else {
                    setIsScanAllowed(false);
                    setNextAllowedScan(`Your next scan is available in ${days} days ${hours} hours ${minutes} minutes`);
                }
            };

            updateDisplay();
            setInterval(updateDisplay, 60000); // Update every minute
        }
    }

    return (
        <>
            {(layout === 'desktop') ? (
                <>
                    {
                        (!acceptedDataNotShared) &&
                        <div>
                            <div>
                                {HealthTrackerBanner()}
                            </div>
                            <div className="p-4">
                                <div className="flex flex-col sm:flex-row text-center sm:text-start sm:pb-xl">
                                    <div
                                        className="flex flex-col order-1 justify-center sm:order-2 sm:basis-4/5 sm:px-md">
                                    <span className="text-[2em] sm:text-[2.5em] text-sherpa-blue">
                                        <span
                                            className="text-[1.2em] text-sherpa-blue"><strong>{auth.user?.profile.nickname}</strong>
                                        </span>,&nbsp;
                                        <br className={"sm:hidden"}/>
                                        <strong>rest assured</strong>, your personal health data will&nbsp;
                                        <br className={"sm:hidden"}/>
                                        <strong>NEVER</strong>&nbsp;
                                        <br className={"sm:hidden"}/>
                                        be shared with your employer.
                                        <br/>
                                        <strong>Your scan is best performed on your mobile.</strong>
                                    </span>
                                    </div>
                                    <div
                                        className="flex justify-center items-center w-full my-lg order-2 sm:order-1 sm:basis-1/5">
                                        <img src={imgHandsAroundShield} alt="sometext"
                                             className="h-[8em] sm:h-[10em]"/>
                                    </div>
                                </div>
                                <div className="sm:flex sm:flex-row place-content-center">
                                    <div
                                        onClick={acceptDataSharingNotice}
                                        className="flex rounded-xl bg-lime-orange p-4 font-medium items-center
                                space-x-4 cursor-pointer justify-center hover:no-underline h-full sm:w-[14.875em] text-center"
                                    >
                                <span className="text-h1 flex flex-row align-middle">
                                    <strong>NEXT</strong>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (acceptedDataNotShared) &&
                        <div>
                            {HealthTrackerBanner()}
                            <div className="flex flex-col md:flex-row md:gap-xl md:my-xl overflow-x-hidden">
                                <div id="htInfoMessage"
                                     className="flex flex-col sm:basis-3/4 sm:gap-lg xl:justify-between">
                                    <div
                                        className="flex flex-row rounded-xl bg-lime-disclaimer p-4 font-medium justify-center mb-md gap-sm">
                                        <div className="flex flex-shrink-0 ml-[-1em] mt-[-2em]">
                                            <img src={imgDoctor}
                                                 alt="Image of doctor with raised hand"
                                                 className="h-[13.6em] w-[10em] sm:h-[17em] sm:w-[14em]"/>
                                        </div>
                                        <div className="ml-[-3em] sm:text-[1.25em]">
                                            <div className="">
                                                The Health Tracker is <strong>not a substitute for the clinical
                                                judgement of a
                                                health care professional</strong>. It’s intended to provide you with
                                                information
                                                to monitor your health through a monthly facial scan.
                                            </div>
                                            <br/>
                                            <div className="ml-[-6.2em] mt-[-1em] sm:m-0">
                                                The Health Tracker <strong>does not diagnose, treat, prevent any
                                                diseases,
                                                symptoms or disorders</strong>. You should <strong>consult a health care
                                                professional</strong> or the emergency services if you believe you have
                                                a
                                                medical issue.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:flex sm:flex-row sm:gap-xl">
                                        <div className="mb-md md:m-0 sm:basis-1/2">
                                            <div
                                                onClick={navigateToHome}
                                                rel="noreferrer"
                                                className={`flex flex-col rounded-xl bg-lime-orange p-4 sm:p-[2em] font-medium items-center
                                        h-full text-center ${isScanAllowed ? 'cursor-pointer hover:no-underline' : 'cursor-not-allowed'}`}
                                                style={{pointerEvents: isScanAllowed ? 'auto' : 'none'}}>
                                            <span className="text-[1.4em] sm:text-[2em] sm:pb-2 flex flex-row">
                                                <strong>
                                                    TAKE YOUR&nbsp;
                                                    <br className="hidden sm:flex"/>
                                                    MONTHLY SCAN
                                                </strong>
                                            </span>
                                                <span className="flex flex-row text-p3 sm:text-[1em]">
                                                {nextAllowedScan}
                                            </span>
                                            </div>
                                        </div>
                                        <div className="mb-md md:m-0 sm:basis-1/2">
                                            <div
                                                onClick={navigateToPreviousResults}
                                                rel="noreferrer"
                                                className="flex flex-col rounded-xl bg-barley-white p-4 sm:p-[2em] font-medium justify-center items-center
                                            cursor-pointer hover:no-underline h-full text-center"
                                            >
                                            <span className="text-[1.4em] sm:text-[2em] flex flex-row">
                                                <strong>
                                                    VIEW YOUR&nbsp;
                                                    <br className="hidden sm:flex"/>
                                                    PREVIOUS RESULT
                                                </strong>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="htInfoDocs"
                                     className="flex flex-row sm:flex-col md:basis-1/4 gap-sm sm:gap-xl items-stretch basis-1/4">
                                    <div className="flex flex-col basis-1/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'General FAQs').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                    space-x-4 p-7 sm:p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                            <div
                                                className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                        justify-between items-center ">
                                        <span
                                            className="text-lime-blue leading-5 text-[1.25em] sm:text-[1.875em]
                                                      sm:me-md md:me-0 md:mb-md md:text-center">
                                            FAQ
                                        </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="flex flex-col gap-sm sm:gap-xl basis-2/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                        justify-between items-center ">
                                        <span className="text-lime-blue  text-[1.25em] sm:text-[1.875em]
                                                        sm:me-md md:me-0 md:mb-md md:text-center">
                                            Service Summary
                                        </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                            </div>
                                        </a>
                                        <div
                                            onClick={navigateToDocs}
                                            className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                        justify-between items-center ">
                                        <span className="text-lime-blue leading-5 text-[1.25em] sm:text-[1.875em]
                                                        sm:me-md md:me-0 md:mb-md md:text-center">
                                            Documents
                                        </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            ) : (layout == 'tablet') ? (
                <>
                    {HealthTrackerBanner()}
                    <div className="flex flex-col basis-3/5 gap-xl h-full">
                        {
                            (!acceptedDataNotShared) &&
                            <div className="p-sm">
                                <div className="flex flex-col">
                                    <div className="flex flex-row place-content-center text-center">
                                        <span className="text-[1.5em] text-sherpa-blue">
                                            <span
                                                className="text-[1.75em] text-sherpa-blue"><strong>{auth.user?.profile.nickname}</strong></span>,&nbsp;
                                            <br/>
                                            <strong>rest assured</strong>, your personal health data will&nbsp;
                                            <br/>
                                            <strong>NEVER</strong>&nbsp;
                                            <br/>
                                            be shared with your employer.
                                            <br/>
                                            <strong>Your scan is best performed on your mobile.</strong>
                                        </span>
                                    </div>
                                    <div className="flex flex-row place-content-center order-2 my-md">
                                        <img src={imgHandsAroundShield} alt="handshield"
                                             className="h-[8em] sm:h-[10em]"/>
                                    </div>
                                </div>
                                <div className="flex flex-row place-content-center">
                                    <div
                                        onClick={acceptDataSharingNotice}
                                        className="rounded-xl bg-lime-orange font-medium text-center p-sm cursor-pointer hover:no-underline w-1/2">
                                        <span className="text-h1">
                                            <strong>NEXT</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (acceptedDataNotShared) &&
                            <div className="flex font-medium justify-between mb-md gap-xl basis-3/5">
                                <div id="htInfoMessage"
                                     className="flex flex-col basis-3/4 gap-lg">
                                    <div
                                        className="flex flex-row rounded-xl bg-lime-disclaimer p-4 font-medium justify-center mb-md gap-sm">
                                        <div className="ml-[-1em] mt-[-2em]">
                                            <img src={imgDoctor}
                                                 alt="Image of doctor with raised hand"
                                                 className="h-[13em] w-[10em] float-left"/>
                                            <p className="text-[1.2em] pt-[2em] m-[1em]">
                                                The Health Tracker is <strong>not a substitute for the clinical
                                                judgement of a
                                                health care professional</strong>. It’s intended to provide you
                                                with
                                                information
                                                to monitor your health through a monthly facial scan.
                                                <br/>
                                                The Health Tracker <strong>does not diagnose, treat, prevent any
                                                diseases,
                                                symptoms or disorders</strong>. You should <strong>consult a
                                                health
                                                care
                                                professional</strong> or the emergency services if you believe
                                                you
                                                have
                                                a
                                                medical issue.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-xl">
                                        <div className="mb-md md:m-0 basis-1/2">
                                            <div
                                                onClick={navigateToHome}
                                                rel="noreferrer"
                                                className={`flex flex-col rounded-xl bg-lime-orange p-4 font-medium items-center
                                    h-full text-center ${isScanAllowed ? 'cursor-pointer hover:no-underline' : 'cursor-not-allowed'}`}
                                                style={{pointerEvents: isScanAllowed ? 'auto' : 'none'}}>
                                        <span className="text-[2em] p-2 flex flex-row">
                                            <strong>
                                                TAKE YOUR&nbsp;
                                                <br />
                                                MONTHLY SCAN
                                            </strong>
                                        </span>
                                                <span className="flex flex-row text-[1em]">
                                            {nextAllowedScan}
                                        </span>
                                            </div>
                                        </div>
                                        <div className="mb-md md:m-0 basis-1/2">
                                            <div
                                                onClick={navigateToPreviousResults}
                                                rel="noreferrer"
                                                className="flex flex-col rounded-xl bg-barley-white p-[2em] font-medium justify-center items-center
                                        cursor-pointer hover:no-underline h-full text-center"
                                            >
                                        <span className="text-[2em] flex flex-row">
                                            <strong>
                                                VIEW YOUR&nbsp;
                                                <br />
                                                PREVIOUS RESULT
                                            </strong>
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-md">
                                    <div className="flex flex-col basis-1/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'General FAQs').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                                        space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                                        visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                            <div
                                                className="flex flex-col justify-center items-center w-full h-full">
                                                        <span
                                                            className="text-lime-blue text-[1.875em] text-center">
                                                            FAQ
                                                        </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="flex flex-col basis-1/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="flex flex-col justify-center items-center w-full h-full text-center">
                            <span className="text-lime-blue text-[1.875em]">
                                Service Summary
                            </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="flex flex-col basis-1/3">
                                        <a
                                            onClick={navigateToDocs}
                                            className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="flex flex-col justify-center items-center w-full h-full">
                            <span className="text-lime-blue text-[1.875em]">
                                Documents
                            </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
            ) : (
                <>
                    {
                        (!acceptedDataNotShared) &&
                        <>
                            {HealthTrackerBanner()}
                            <div className="p-sm">
                                <div className="flex flex-col">
                                    <div className="flex place-content-center text-center">
                                        <span className="text-[1em] text-sherpa-blue">
                                            <span
                                                className="text-[1.3em] text-sherpa-blue">
                                                <strong>{auth.user?.profile.nickname}</strong></span>,&nbsp;
                                            <br/>
                                            <strong>rest assured</strong>, your personal health data will&nbsp;
                                            <br/>
                                            <strong>NEVER</strong>&nbsp;
                                            <br/>
                                            be shared with your employer.
                                            <br />
                                             <strong>Your scan is best performed on your mobile.</strong>
                                        </span>
                                    </div>
                                    <div className="flex place-content-center my-sm">
                                        <img src={imgHandsAroundShield} alt="handshield"
                                             className="h-[4em] w-[6em]"/>
                                    </div>
                                </div>
                                <div className="flex flex-row place-content-center">
                                    <div
                                        onClick={acceptDataSharingNotice}
                                        className="rounded-xl bg-lime-orange font-medium 
                                        text-center p-sm cursor-pointer hover:no-underline w-1/2">
                                        <span className="text-[1.4em]">
                                            <strong>NEXT</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        (acceptedDataNotShared) &&
                        <div>
                            {HealthTrackerBanner()}
                            <div className="flex flex-col py-sm">
                                <div id="htInfoMessage">
                                    <div
                                        className="flex flex-row rounded-xl bg-lime-disclaimer font-medium p-2 mb-sm">
                                        <div className="ml-[-0.5em] mt-[-2em]">
                                            <img src={imgDoctor}
                                                 alt="Image of doctor with raised hand"
                                                 className="h-[10em] w-[7em] float-left"/>
                                            <p className="text-p2 pt-[2em] m-[1em]">
                                                The Health Tracker is <strong>not a substitute for the clinical
                                                judgement of a
                                                health care professional</strong>. It’s intended to provide you
                                                with
                                                information
                                                to monitor your health through a monthly facial scan.
                                                <br/>
                                                The Health Tracker <strong>does not diagnose, treat, prevent any
                                                diseases,
                                                symptoms or disorders</strong>. You should <strong>consult a
                                                health
                                                care
                                                professional</strong> or the emergency services if you believe
                                                you
                                                have
                                                a
                                                medical issue.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    onClick={navigateToHome}
                                    rel="noreferrer"
                                    className={`flex flex-col rounded-xl justify-center bg-lime-orange 
                                    font-medium cursor-pointer hover:no-underline w-full py-sm
                                    ${isScanAllowed ? 'cursor-pointer hover:no-underline' : 'cursor-not-allowed'}`}
                                    style={{pointerEvents: isScanAllowed ? 'auto' : 'none'}}>
                                    <span className="text-[1.4em] text-center">
                                            <strong>
                                                TAKE YOUR MONTHLY SCAN
                                            </strong>
                                    </span>
                                    <span className="text-[1em] text-center">
                                       {nextAllowedScan}
                                    </span>
                                </div>
                                <div
                                    onClick={navigateToPreviousResults}
                                    rel="noreferrer"
                                    className="flex flex-col rounded-xl font-medium justify-center bg-barley-white
                                                cursor-pointer hover:no-underline h-full text-center py-sm my-sm">
                                    <span className="text-[1.4em]">
                                        <strong>
                                            VIEW YOUR PREVIOUS RESULT
                                        </strong>
                                    </span>
                                </div>
                                <div id="htInfoDocs"
                                     className="flex flex-row sm:flex-col md:basis-1/4 gap-sm sm:gap-xl items-stretch basis-1/4">
                                    <div className="flex flex-col basis-1/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'General FAQs').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                        space-x-4 p-7 sm:p-3 cursor-pointer justify-between hover:no-underline
                                        visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                            <div
                                                className="w-full h-full flex flex-col sm:flex-row justify-center md:flex-col
                                            items-center ">
                                            <span
                                                className="text-lime-blue leading-5 text-[1.25em] sm:text-[1.875em]
                                                          sm:me-md md:me-0 md:mb-md md:text-center">
                                                FAQ
                                            </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="flex flex-col gap-sm sm:gap-xl basis-2/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                        space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                        visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                            justify-between items-center ">
                                            <span className="text-lime-blue  text-[1.25em] sm:text-[1.875em]
                                                            sm:me-md md:me-0 md:mb-md md:text-center">
                                                Service Summary
                                            </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                            </div>
                                        </a>
                                        <div
                                            onClick={navigateToDocs}
                                            className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                        space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                        visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                            justify-between items-center ">
                                            <span className="text-lime-blue leading-5 text-[1.25em] sm:text-[1.875em]
                                                            sm:me-md md:me-0 md:mb-md md:text-center">
                                                Documents
                                            </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )
            }
        </>
    );
}

export default ProductInfo;